import React, { Component } from 'react';

import { withLocaleStore } from '../../../stores/LocaleStore'

const regex = /[.,\s]/gi;

class FacetComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.collapsed !== undefined? this.props.collapsed : false,
      showCounters: this.props.showCounters !== undefined? this.props.showCounters : true
    };
    this.handleShowMore = this.handleShowMore.bind(this);
    this.handleShowLess = this.handleShowLess.bind(this);
    this.handleSimpleValueToggle = this.handleSimpleValueToggle.bind(this);
    this.handleRangeValueToggle = this.handleRangeValueToggle.bind(this);
    this.handleBooleanValueToggle = this.handleBooleanValueToggle.bind(this);
  }

  handleSimpleValueToggle(event) {
    const { facet, onToggle } = this.props;
    onToggle(event.target.checked, facet.field, event.target.name, 'SimpleFacetValue');
  }

  handleRangeValueToggle(event) {
    const { facet, onToggle } = this.props;
    let from = event.target.getAttribute('data-from');
    let to = event.target.getAttribute('data-to');
    onToggle(event.target.checked, facet.field, [from, to], 'RangeFacetValue');
  }

  handleBooleanValueToggle(event) {
    const { facet, onToggle } = this.props;
    onToggle(event.target.checked, facet.field, event.target.name, 'BooleanFacetValue');
  }

  handleShowMore(event) {
    event.preventDefault();
    const { onExpand } = this.props;
    let field = event.target.attributes['data-target'].nodeValue;
    let limit = parseInt(event.target.attributes['data-value'].nodeValue) + 10;
    onExpand(field, limit);
  }

  handleShowLess(event) {
    event.preventDefault();
    const { onExpand } = this.props;
    let field = event.target.attributes['data-target'].nodeValue;
    onExpand(field, 10);
  }

  render() {
    const { facet, entityType, localeStore } = this.props;
    const collapsed = this.state.collapsed && !(facet.selectedValues.length > 0);
    const field = facet.field.replace(regex, '-');
    const target = field + '-target';

    let optionsButton = this.renderOptionsButton(facet);

    return (
      <div className='facet'>
        <div className='accordion' id={field}>
          <a className='button' href='#' data-toggle='collapse' data-target={'#'+ target} aria-expanded={collapsed? 'false' : 'true'} aria-controls='collapseFacet'>
            <h2 className='title mb-0'>
              {localeStore.intl.formatMessage({'id' : 'retrievo.ui.search.facets.' + entityType + '.' + facet.field + '.title'})}
            </h2>
            <span className='fas button-caret'/>
          </a>
        </div>
        <div id={target} className={'facet-items collapse' + (collapsed? '' : ' show')} aria-labelledby='headingOne' data-parent={'#'+ field}>
          {this.renderFacetValues()}
          <div className='facet-limit'>
            {optionsButton}
          </div>
        </div>
      </div>
    );
  }

  renderFacetValues() {
    const { facet, selected } = this.props;
    return facet.values.map((value, pos) => {
      switch (value.type) {
        case 'SimpleFacetValue' :
          return this.renderSimpleFacetValue(facet, value, selected, pos);
        case 'RangeFacetValue' :
          return this.renderRangeFacetValue(facet, value, selected, pos);
        case 'BooleanFacetValue' :
          return this.renderBooleanFacetValue(facet, value, selected, pos);
      }
    })
  }


  renderSimpleFacetValue(facet, value, selected, pos) {
    const { localeStore, entityType } = this.props;
    let checked = (selected === undefined ? 0 : selected.selectedValues.includes(value.key));
    let target = facet.field.toLowerCase().replace(regex, '_') + '_' +
      value.key.toLowerCase().replace(regex, '_') + '_' + pos;
    let label = localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.' + entityType + '.' +
        facet.field + '.' + value.label + '.label', defaultMessage: value.label});
    return (
      <div key={target} className='form-group form-check'>
        <input
          id={target}
          type='checkbox'
          className='form-check-input'
          name={value.key}
          onChange={this.handleSimpleValueToggle}
          checked={checked}/>
        <label className='form-check-label' >
        {label}
          {this.state.showCounters?
            <span className='form-check-label counter' >{value.count > 0 ? '(' + value.count + ')' : ''}</span>
            :
            ''
          }
        </label>
      </div>
    )
  }

  renderRangeFacetValue(facet, value, selected, pos) {
    let checked = (selected === undefined ? 0 : selected.selectedValues.includes(value.from) && selected.selectedValues.includes(value.to));
    let target = facet.field.toLowerCase().replace(regex, '_') + '_' +
      value.key.toLowerCase().replace(regex, '_') + '_' + pos;
    return (
      <div key={target} className='form-group form-check'>
        <input
          id={target}
          type='checkbox'
          className='form-check-input'
          name={value.key}
          data-from={value.from}
          data-to={value.to}
          onChange={this.handleRangeValueToggle}
          checked={checked}/>
        <label className='form-check-label'>
        {value.label}
          {this.state.showCounters?
            <span className='form-check-label counter'>{value.count > 0 ? '(' + value.count + ')' : ''}</span>
            :
            ''
          }
        </label>
      </div>
    )
  }

  renderBooleanFacetValue(facet, value, selected, pos) {
    const { localeStore, entityType } = this.props;
    let checked = (selected === undefined ? 0 : selected.selectedValues.includes(value.key));
    let target = facet.field.toLowerCase().replace(regex, '_') + '_' +
      value.key.toLowerCase().replace(regex, '_') + '_' + pos;

    let label = localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.' + entityType + '.' +
        facet.field + '.' + value.label + '.label', defaultMessage: value.label});
    return (
      <div key={target} className='form-group form-check'>
        <input
          id={target}
          type='checkbox'
          className='form-check-input'
          name={value.key}
          onChange={this.handleBooleanValueToggle}
          checked={checked}/>
        <label className='form-check-label'>
        {label}
          {this.state.showCounters?
            <span className='form-check-label counter'>{value.count > 0 ? '(' + value.count + ')' : ''}</span>
            :
            ''
          }
        </label>

      </div>
    )
  }


  renderOptionsButton(facet) {
    const { localeStore } = this.props;

    if(facet.values.length < 1) {
      return undefined;
    }
    else if (facet.values[0]['type'] === 'RangeFacetValue' || facet.values[0]['type'] === 'AutoRangeFacetValue') {
      return undefined;
    }
    else if (facet.minCount > 0 && facet.values.length < facet.totalMatchCount && facet.totalMatchCount > 10) {
      return (
        <a onClick={this.handleShowMore}
           data-target={facet.field}
           data-value={facet.values.length}
           href='#'>
          {localeStore.intl.formatMessage({'id' : 'retrievo.ui.search.facets.show.more'})}
        </a>
      );
    }
    else if (facet.minCount < 1 && facet.values.length < facet.totalCount && facet.totalCount > 10) {
      return (
        <a onClick={this.handleShowMore}
           data-target={facet.field}
           data-value={facet.values.length}
           href='#'>
          {localeStore.intl.formatMessage({'id' : 'retrievo.ui.search.facets.show.more'})}
        </a>
      );
    } else if (facet.minCount > 0 && facet.values.length >= facet.totalMatchCount && facet.totalMatchCount > 10) {
      return (
        <a onClick={this.handleShowLess}
           data-target={facet.field}
           data-value='10'
           href='#'>
          {localeStore.intl.formatMessage({'id' : 'retrievo.ui.search.facets.show.less'})}
        </a>
      );
    } else if (facet.minCount < 1 && facet.values.length >= facet.totalCount && facet.totalCount > 10) {
      return (
        <a onClick={this.handleShowLess}
           data-target={facet.field}
           data-value='10'
           href='#'>
          {localeStore.intl.formatMessage({'id' : 'retrievo.ui.search.facets.show.less'})}
        </a>
      );
    } else {
      return undefined;
    }
  }


}

export default withLocaleStore(FacetComponent);
