// External Libs/Components
import React, { Component } from 'react';
import { toast } from 'react-toastify';

// Global Components
import EntityComponent from 'components/entity/EntityComponent'
import FileFormComponent from 'components/entity/form/FileFormComponent';

// Stores
import { EntityContext } from 'stores/EntityStore'
import { withLocaleStore } from 'stores/LocaleStore';
import { withRouter } from 'react-router-dom';


class CsvDataConnectorComponent extends Component {

  constructor(props) {
    super(props);
    this.openFileSubmissionDialog = this.openFileSubmissionDialog.bind(this);
    this.handleCreateCsvHarvestTask = this.handleCreateCsvHarvestTask.bind(this);
    this.handleCreateHarvestMediaTask = this.handleCreateHarvestMediaTask.bind(this);
    this.handleCreateReIngestTask = this.handleCreateReIngestTask.bind(this);
    this.handleCreateUpdateVisibilityTask = this.handleCreateUpdateVisibilityTask.bind(this);
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  render() {
    const { localeStore, onChange, onDelete, dataConnector, dataSource } = this.props;

    let title = (
      <React.Fragment>
        <span className='fas fa-cog'/>
        { dataConnector['name']? dataConnector['name'] :
          localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.CSV.create'})
        }
      </React.Fragment>
    );

    let context = 'data_sources/' + dataSource['id'] + '/data_connectors';
    let link = '/admin/' + context + '/' + dataConnector['id'];

    let viewerFields = [
      'csv_slug',
      'harvest',
      'harvestThumbnail',
      'harvestFulltext',
      'lastHarvest',
      'lastThumbnailHarvest',
      'lastFulltextHarvest',
      'recordsValid',
      'thumbnailsValid',
      'fulltextsValid'
    ];

    let formFields = [
      'name',
      'csv_slug',
      'harvest',
      'harvestThumbnail',
      'harvestFulltext',
      'lastHarvest',
      'lastThumbnailHarvest',
      'lastFulltextHarvest',
    ];

    if(dataConnector.id === undefined) {
      formFields = [
        'name',
        'csv_slug',
        'harvest',
        'harvestThumbnail',
        'harvestFulltext',
      ];
    }

    if(!dataConnector['csv_slug']  && dataConnector['software']) {
      dataConnector['csv_slug'] = dataConnector['slug'];
    }

    // 20201002 hsilva: this should be configurable
    // dataConnector['harvestThumbnail'] = 'true';
    // dataConnector['harvestFulltext'] = 'true';

    return (
      <div className='row data-connector'>
        <EntityComponent
          title={title}
          context={context}
          link={'/#' + link}
          edit={dataConnector.id === undefined}
          entity={dataConnector}
          entityType={'data_connectors'}
          actions={this.renderActions()}
          viewerFields={viewerFields}
          formFields={formFields}
          visitable={true}
          onChange={onChange}
          onDelete={onDelete}
        />
      </div>
    );
  }

  renderActions() {
    const { localeStore, dataConnector } = this.props;
    return (
      <div className="dropdown">
        <button className="btn btn-sm btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className='fas fa-cog' />
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.tasks' })}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a href='#' className='dropdown-item' onClick={this.openFileSubmissionDialog}>
            <span className='fas fa-plus' />
            {localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.CSV_HARVEST.create.button' })}
          </a>
          <a href='#' className='dropdown-item' onClick={this.handleCreateHarvestMediaTask}>
            <span className='far fa-file'/>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.create.button'}) }
          </a>
          <a href='#' className='dropdown-item' onClick={this.handleCreateReIngestTask}>
            <span className='fas fa-redo'/>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.button'}) }
          </a>
          <a href='#' className='dropdown-item' onClick={this.handleCreateUpdateVisibilityTask}>
            {dataConnector['visible']?
              <span className='fas fa-eye-slash'/> :
              <span className='fas fa-eye'/>
            }
            {dataConnector['visible']?
              localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.hide.create.button'}) :
              localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.show.create.button'})
            }
          </a>
        </div>
      </div>
    );
  }

  openFileSubmissionDialog(event) {
    event.preventDefault();
    const { localeStore } = this.props;

    let title = localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.CSV_HARVEST.create.title'});
    let message = localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.CSV_HARVEST.create.description'});
    let cancel = localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.CSV_HARVEST.create.button.cancel'});

    toast.success(
      <FileSubmissionDialog
        title={title}
        field={'create_csv_harvest_task.file'}
        message={message}
        cancelButton={cancel}
        successToast={this.handleCreateCsvHarvestTask}
      />,
      { autoClose: false, closeOnClick: false }
    );
  }

  handleCreateCsvHarvestTask(entity, entityFiles) {
    const { localeStore } = this.props;

    let dataConnector = this.props.dataConnector;

    let task = {
      type: 'CSV_HARVEST',
      dataConnectorId: dataConnector['id'],
      dataSourceId: dataConnector['dataSourceId']
    };

    let entityFile = entityFiles[0];

    this.context.actions.post(task, {context: 'tasks'})
      .then((result) => {
        this.context.actions
          .post(entityFile['data'], { context: 'tasks/' + result.data['id'] + '/file'})
          .then(() => {
            this.props.history.push({
              pathname: '/admin/tasks/' + result.data['id'],
            });
          })
          .catch(() => {
            let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.CSV_HARVEST.create.error'});
            toast.error(errorMessage);
          });
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage(
          {id: 'retrievo.ui.data_connector.CSV_HARVEST.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(
          <SimpleDialog
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.CSV_HARVEST.create.title'})}
            message={errorMessage}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.CSV_HARVEST.create.button.cancel'})}
          />
        );
      });
  }

  handleCreateHarvestMediaTask(event) {
    event.preventDefault();

    const { localeStore, dataConnector } = this.props;
    let task = {
      type: 'FULLTEXT_THUMBNAIL',
      dataConnectorId: dataConnector['id'],
      dataConnectorVersion: dataConnector['version'],
      dataSourceId: dataConnector['dataSourceId']
    };

    this.context.actions.post(task, { context: 'tasks' })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }

  handleCreateReIngestTask(event) {
    event.preventDefault();

    const { localeStore, dataConnector } = this.props;

    let task = {
      type: 'REPROCESS',
      dataConnectorId: dataConnector['id'],
      dataConnectorVersion: dataConnector['version'],
      dataSourceId: dataConnector['dataSourceId']
    };

    this.context.actions.post(task, { context: 'tasks' })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_connector.REPROCESS.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }

  handleCreateUpdateVisibilityTask(event) {
    event.preventDefault();

    const { localeStore, dataConnector } = this.props;
    this.context.actions.put({}, { context: 'data_sources/' + dataConnector['dataSourceId']
      + '/data_connectors/' + dataConnector['id'] + '/visibility' },
      { visible: !dataConnector['visible'] })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }
}


const FileSubmissionDialog = ({ closeToast, successToast, title, message, cancelButton, field}) => (
  <div className='csv-file-submission'>
    <h2 className='toast-title' dangerouslySetInnerHTML={{__html : title}}/>
    <div className='toast-body'>
      <span className='toast-message' dangerouslySetInnerHTML={{__html : message}}/>
        <FileFormComponent
          field={field}
          entity={{}}
          entityType={'task'}
          entityFiles={[]}
          onEntityChange={(entity, entityFiles) => {
            successToast(entity, entityFiles);
            closeToast();
          }}
        />
    </div>
    <div className='toast-actions'>
      <button className='btn btn-outline-primary' onClick={closeToast}>{cancelButton}</button>
    </div>
  </div>
);

const SimpleDialog = ({ closeToast, title, message, cancelButton}) => (
  <div className='csv-file-submission'>
    <h2 className='toast-title' dangerouslySetInnerHTML={{__html : title}}/>
    <div className='toast-body'>
      <span className='toast-message' dangerouslySetInnerHTML={{__html : message}}/>
    </div>
    <div className='toast-actions'>
      <button className='btn btn-outline-primary' onClick={closeToast}>{cancelButton}</button>
    </div>
  </div>
);

const SuccessDialog = ({ closeToast, title, message, taskId, successButton, cancelButton}) => (
  <div className='csv-file-submission'>
    <h2 className='toast-title' dangerouslySetInnerHTML={{__html : title}}/>
    <div className='toast-body'>
      <span className='toast-message' dangerouslySetInnerHTML={{__html : message}}/>
    </div>
    <div className='toast-actions'>
      { successButton ? <a href={'/#/admin/tasks/' + taskId} className='btn btn-primary' style={{marginRight: '.2rem'}}>{successButton}</a> : '' }
      { cancelButton ? <button className='btn btn-outline-primary' onClick={closeToast}>{cancelButton}</button> : '' }

    </div>
  </div>
);

CsvDataConnectorComponent.contextType = EntityContext;

export default withRouter(
  withLocaleStore(
    CsvDataConnectorComponent
  )
)
