// External Libs/Components
import React, { Component } from 'react';
import { withLastLocation } from 'react-router-last-location';

// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'

// Container Components
import FacetsComponent from 'components/search/facets/FacetsComponent'
import SearchResultsComponent from 'components/search/results/SearchResultsComponent'
import SearchBarComponent from 'components/search/bars/SearchBarComponent'
import RecordResultComponent from './search/RecordSearchResultComponent'
import RefineSearchComponent from 'components/search/controls/RefineSearchComponent'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { withConfigStore } from 'stores/ConfigStore';
import {SearchStore, SearchContext, withSearchStore} from 'stores/SearchStore'

// CSS (css paths are the only relative paths)
import './_.css';

class RecordsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageWidth: window.innerWidth
    };
    this.updatePageWidth = this.updatePageWidth.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatePageWidth);
    const componentQueryParameters = this.props.location.search;
    this.context.actions.updateResults(componentQueryParameters);
    if(this.props.lastLocation !== null) {
      // eslint-disable-next-line no-useless-escape
      let pattern = new RegExp('\/records\/[0-9a-fA-F]{24}');
      if(pattern.test(this.props.lastLocation.pathname)) {
        window.scrollTo(0, 0);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const stateQueryParameters = this.context.actions.getQueryParametersFromState();
    const oldQueryParameters = prevProps.location.search;
    const currentQueryParameters = this.props.location.search;

    if(stateQueryParameters !== oldQueryParameters) {
      this.context.actions.updateResults(stateQueryParameters);
    } else if ( stateQueryParameters !== currentQueryParameters) {
      this.context.actions.updateResults(currentQueryParameters);
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePageWidth);
  }

  updatePageWidth() {
    this.setState({ pageWidth: window.innerWidth });
  }

  render() {
    return (
      <SearchStore entity='records' context='records'>
        <NavbarComponent/>
        <section id='main-content' className='records'>
          <RecordsHeader/>
          <div className='container-fluid public-container search'>
            <div className='row'>
              <div className='col-12 col-lg-8 col-xl-9 order-lg-8'>
                {this.renderRefineSearch()}
                <SearchResultsComponent SearchResultComponent={RecordResultComponent}/>
              </div>
              {this.renderFilters()}
            </div>
          </div>
          {this.renderFiltersMobile()}
        </section>
        <FooterComponent/>
      </SearchStore>
    );
  }

  renderRefineSearch() {
    return (
      <RefineSearchComponent/>
    );
  }

  renderFilters() {
    if(this.state.pageWidth >= 992) {
      return (
        <div className='d-none d-lg-block col-lg-4 col-xl-3 order-lg-1'>
          <div className='sticky-top'>
            <FacetsComponent/>
          </div>
        </div>
      );
    }
  }

  renderFiltersMobile() {
    const { localeStore } = this.props;
    if(this.state.pageWidth < 992 ) {
      return (
        <div className='col d-lg-none filters-mobile'>
          <div className='accordion' id='accordionExample'>
            <button className='btn btn-primary btn-lg btn-block filters-mobile-btn'
                    type='button'
                    data-toggle='collapse'
                    data-target='#collapseOne'
                    aria-expanded='true'
                    aria-controls='collapseOne'>
              {localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.title'})}
            </button>
          </div>
          <div id='collapseOne' className='collapse filters-container' aria-labelledby='headingOne' data-parent='#accordionExample'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 close-button'>
                  <button className='btn btn-link'
                          type='button'
                          data-toggle='collapse'
                          data-target='#collapseOne'
                          aria-expanded='true'
                          aria-controls='collapseOne'>
                    <span className='fas fa-times'/>
                    {localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.close'})}
                  </button>
                </div>
                <div className='col-12'>
                  <FacetsComponent/>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

}

const RecordsHeaderComponent = class RecordsHeaderComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
    this.moreInfo = this.moreInfo.bind(this);
  }

  renderInfo(){
    const { localeStore , configStore } = this.props;
    if(configStore.actions.getProperty('retrievo.ui.search.moreInfo.enable') === "true") {
      return (
        <a className="show-more" href='#' onClick={this.moreInfo}>
          {this.state.showMore ? (
          <span>{localeStore.intl.formatMessage({id: 'retrievo.ui.search.moreInfo.hide'}) }</span>
          ) : (
          <span>{localeStore.intl.formatMessage({id: 'retrievo.ui.search.moreInfo.show'}) }</span>
          )}
        </a>
      )
    }
  }

  renderMoreInfo() {
    const { localeStore } = this.props;
    const showMore = this.state.showMore;
    if(showMore) {
      return (
         <p className="moreInfo" dangerouslySetInnerHTML={{ __html: localeStore.intl.formatMessage({id: 'retrievo.ui.search.moreInfo.text'}) }} />
      );
    }
  }

  moreInfo(event){
    event.preventDefault();
    this.state.showMore == false ? (
      this.setState({ showMore: true })
    ) : (
      this.setState({ showMore: false })
    )
  }

  render() {
    const { localeStore, searchStore } = this.props;
    const result = searchStore.data.result;
    let info = '';
    if(result.items.length > 0) {
      let sources = result.facets.find((facet) => { return facet.field === 'dataSourceId' });
      let sourcesTotal = sources? sources.values.length : 0;
      info = localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.records.description'},
        { total_records: result.total.toLocaleString(), total_sources: sourcesTotal });
    }

    return (
      <div className='public-header'>
        <div className='container-fluid public-container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='container-title'>{localeStore.intl.formatMessage({id: 'retrievo.ui.records.title'}) }</h1>
              <p className='container-description' dangerouslySetInnerHTML={{ __html: info }}/>
              {this.renderInfo()}
              {this.renderMoreInfo()}
              <SearchBarComponent/>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const RecordsHeader = withLocaleStore(withConfigStore(withSearchStore(RecordsHeaderComponent)));

RecordsComponent.contextType = SearchContext;


export default withLocaleStore(withLastLocation(RecordsComponent));
