// External Libs/Components
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Handlebars from 'handlebars'

// Global Components

// Container Components

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { EntityContext } from 'stores/EntityStore';

// CSS (css paths are the only relative paths)
import './_.css';

class RecordComponent extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let { localeStore, configStore } = this.props;
    let record = this.props.record;
    var fromSearchResults = this.props.location.state ? this.props.location.state.fromSearch || false : false;


    Handlebars.registerHelper('contains', function (text, value, options) {
      text = Handlebars.escapeExpression(text);
      value = Handlebars.escapeExpression(value);
      return (value.indexOf(text) > -1) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('startsWith', function (text, value, options) {
      text = Handlebars.escapeExpression(text);
      value = Handlebars.escapeExpression(value);
      return (value.startsWith(text)) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('splitDot', function (text) {
      var t = text.split(".");
      return t[1];
    });

    Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
      return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper("concatStringLabel", function (varValue, varValue1) {
      var junto = varValue + varValue1 + ".label";
      return junto;
    });

    Handlebars.registerHelper("concatString", function (varValue, varValue1) {
      var junto = varValue + varValue1;
      return junto;
    });

    Handlebars.registerHelper('translation', function (field, value) {
      var translation = Handlebars.escapeExpression(field);
      return localeStore.intl.formatMessage({ id: translation, defaultMessage: value });
    })

    Handlebars.registerHelper('component', function (text) {
      return localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.records.component.sections.' + text + '.title' });
    })

    Handlebars.registerHelper('thumbnail', function () {
      var thumb = record['metadataEnhanced']['link']
      return thumb;
    })

    Handlebars.registerHelper('isFilter', function (field) {
      let fieldsAsFilter = configStore.actions.getProperty('retrievo.ui.links.records.as_filter') || [];
      return fieldsAsFilter.includes(field);
    })

    Handlebars.registerHelper('isFacet', function (field) {
      let fieldsAsFilter = configStore.actions.getProperty('retrievo.ui.links.records.as_facet') || [];
      return fieldsAsFilter.includes(field);
    })

    Handlebars.registerHelper('apiUrl', function () {
      let apiUrl = configStore.actions.getApiUrl();
      return apiUrl;
    })

    Handlebars.registerHelper('createArray', function (config) {
      var conf = configStore.actions.getProperty(config) || [];
      var array = String(conf).split(',').map(function (n) {
        return n;
      });
      return array;
    });

    Handlebars.registerHelper('fromSearchResults', function () {
      var value = '';
      if (fromSearchResults) {
        value = "true"
      } else {
        value = "false"
      }
      return value;
    });

    let template = configStore.actions.getProperty('retrievo.ui.entity.records.component.template.' + record['dataConnectorSlug']) ?
      configStore.actions.getProperty('retrievo.ui.entity.records.component.template.' + record['dataConnectorSlug']) : 
      configStore.actions.getProperty('retrievo.ui.entity.records.component.template.default');
    const templateString = Handlebars.compile(template);
    return (
      <div dangerouslySetInnerHTML={{ __html: templateString(record, this.props) }}></div>
    );
  }


}

RecordComponent.contextType = EntityContext;


export default
  withLocaleStore(
    withRouter(
      withConfigStore(RecordComponent)
    )
  );
